<template>
  <v-layout wrap>
    <v-container fluid class="homeSlider" v-if="sliderImg.length > 0">
      <v-carousel :show-arrows="true" hide-delimiters cycle height="605px">
        <v-carousel-item v-for="(item, i) in sliderImg" :key="i">
          <v-img :src="item" height="605px"></v-img>
        </v-carousel-item>
      </v-carousel>
    </v-container>
    <!-- <v-row><CommonHero :image="image" border="border" head="Find Your Perfect Place"></CommonHero></v-row> -->
    <v-container>
      <v-row class="welcome-sec">
        <v-col md="5" lg="5" cols="12" class="wc-head">
          <h1 v-html="welcomeContent.title"></h1>
        </v-col>
        <v-col md="7" lg="7" cols="12">
          <p class="text-justify" v-html="welcomeContent.description">
          </p>
         
        </v-col>
      </v-row>
    </v-container>
    <v-container class="significant">
      <v-row
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'padding-top:0px;text-align: left;'
            : 'padding-top:50px;text-align: left;'
        "
      >
        <v-col md="6">
          <!-- <v-carousel :show-arrows="false" style="border-radius:35px;height:445px">
              <v-carousel-item
                v-for="(item,i) in items"
                :key="i"
                :src="item.img"
              ></v-carousel-item>
        </v-carousel> -->
          <v-img
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'margin-top:0px;border-radius:25px;'
                : 'border-radius:35px;margin-top:70px;'
            "
            :src="significant.image"
            :height="$vuetify.breakpoint.mdAndDown ? '470' : '370'"
          ></v-img>
        </v-col>
        <v-col md="6">
          <v-list class="text-left" v-if="significant.contents.length > 0">
            <v-list-item class="pb-5"
              ><h2>{{significant.title}}:</h2>
              </v-list-item
            >
             <v-list-item
              v-for="(s,i) in significant.contents"
              :key="i"
              :class="'pl-'+s.pl"
              style="
                align-items: self-start;
                text-align: justify;
                margin-bottom: 10px;
                min-height: 30px;
              "
            >
              <v-icon
                class="mr-2"
                color="#181818"
                text
                :size="s.size"
                >mdi-checkbox-blank-circle</v-icon
              >{{ s.text }}
            </v-list-item> 
            <!-- <v-list-item class="pt-5">
              <v-btn
            depressed
            color="#ED1C24"
            class="mt-5 white--text"
            style="padding: 14px 64px;border-radius: 15px;height:48px"
          >
            More About Us
          </v-btn>
            </v-list-item> -->
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'padding-top:50px;'
            : 'padding-top:150px;'
        "
      >
        <v-col
          md="6"
          sm="12"
          class="project-card"
          :class="$vuetify.breakpoint.smAndDown ? 'order-2' : ''"
        >
          <v-card>
            <v-card-title
              style="
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 36px;
                line-height: 50px;
                color: #181818;
              "
              >Our Projects</v-card-title
            >
            <v-card-text class="text-justify"
              >
               <strong>{{projectText.name}}:</strong> {{projectText.description}}
              </v-card-text
            >
            <v-btn
              depressed
              color="#ED1C24"
              class="mt-5 white--text"
              to="/units-concerns"
            >
              View all
            </v-btn>
          </v-card>
        </v-col>
        <v-col
          md="6"
          sm="12"
          class="project-carousel"
          :class="$vuetify.breakpoint.smAndDown ? 'order-1' : ''"
          v-if="projectSlider.length > 0"
        >
          <v-carousel
            hide-delimiters
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'border-radius:25px;'
                : 'border-radius:35px;'
            "
            cycle
            :height="$vuetify.breakpoint.mdAndDown ? '600' : '500'"
          >
            <v-carousel-item
              class="text-right ourProjectSlider"
              v-for="(item, i) in projectSlider"
              :key="i"
            >
              <router-link :to="'/concern/'+item.slug">
                <v-img
                  :src="item.thumbnail"
                  :height="$vuetify.breakpoint.mdAndDown ? '600' : '500'"
                >
                  <p>{{ item.name }}</p>
                </v-img></router-link
              >
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-row class="initiatives">
      <v-col md="12"><h1 class="text-center">Our Initiatives</h1></v-col>
      <v-col md="2" v-for="initiative in initiatives" :key="initiative.image">
        <v-img :src="initiative.image"></v-img>
      </v-col>
    </v-row> -->

    <v-container>
      <v-row class="gallery">
        <v-col md="6">
          <h1 class="text-left">Gallery</h1>
        </v-col>
        <v-col md="6" class="d-flex justify-end">
          <v-icon @click="showPrev">mdi-arrow-left</v-icon>
          <v-icon @click="showNext">mdi-arrow-right</v-icon>
        </v-col>
   
        <v-col md="12" v-if="Gallarys.images.length>0">
          <CoolLightBox
            :items="Gallarys.images"
            :index="index"
            :effect="'fade'"
            @close="index = null"
          >
          </CoolLightBox>
          
          <VueSlickCarousel v-bind="settings" ref="carousel" >
            <div class="pr-3" v-for="(img, i) in Gallarys.images" :key="i">
              <div class="images-wrapper">
                <v-img
                  class="image"
                  contain
                  :src="img"
                  @click="index = i"
                ></v-img>
              </div>
            </div>
          </VueSlickCarousel>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <h1 class="text-center pb-5">Press</h1>
      <v-row class="Latest-realeses" v-if="newses.length > 0">
        <v-col md="4" sm="12" v-for="(news, i) in newses" :key="i">
          <v-card
            class="mx-auto text-left"
            :height="$vuetify.breakpoint.smAndDown ? '750px' : '750px'"
          >
            <VideoPlayer :v_id="news.videoId" v-if="news.videoId" />
            <v-img
              :src="news.thumbnail"
              height="360px"
              v-if="!news.videoId"
            >
            </v-img>
            <v-card-subtitle class="pb-0" v-if="news.released_date">
              Released: {{ news.released_date }}
            </v-card-subtitle>
            <v-card-title style="font-size: 14px"
              ><b>{{ news.title }}</b></v-card-title
            >
            <v-card-text class="text--primary">
              <span style="font-size: 14px">{{  news.description.length > 120 ?  news.description.substr(0, 120) : news.description }}</span>
            </v-card-text>

            <v-card-actions>
              <v-btn color="#ED1C24" text :href="news.link">
                <strong> Read More</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="12" class="justify-center">
          <v-btn depressed color="#ED1C24" class="mt-5 white--text" to="/news">
            View all
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row class="Latest-realeses" v-if="releases.length > 0">
        <v-col md="12 text-center">
          <h1>Latest realeses</h1>
        </v-col>
        <v-col
          md="4  text-left"
          v-for="(realese, index) in releases"
          :key="index"
        >
          <v-card
            class="mx-auto"
            :height="$vuetify.breakpoint.smAndDown ? '600px' : '650px'"
          >
            <v-img :src="realese.thumbnail" height="300px"> </v-img>
            <v-card-subtitle class="pb-0" v-if="realese.released_date">
              Released: {{ realese.released_date }}
            </v-card-subtitle>
            <v-card-title>{{ realese.title }}</v-card-title>
            <v-card-text class="text--primary">
              <div v-if="realese.description.length > 125">
                <div v-html="realese.description.substr(0, 125) + '...'"></div>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn color="#ED1C24" text :href="'/csr/'+realese.slug">
                <strong> Read More</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="12" class="justify-center">
          <v-btn depressed color="#ED1C24" class="mt-5 white--text" to="/csr">
            View all
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// import img from '../assets/images/g1.png'
import initiativeImg from "../assets/images/Initiatives1.png";
// import CommonHero from '@/components/CommonHero.vue'

import VideoPlayer from "@/components/VideoPlayer.vue";
import axios from "axios";
import { baseUrl } from "@/config";

export default {
  components: { VueSlickCarousel, CoolLightBox, VideoPlayer },
  mounted(){
    this.getBannerSlider();
    this.getGallerySlider();
    this.getProjectSlider();
    this.getWelcomeContent();
    this.getReleases();
    this.getNews();
    this.getSignificant();
  },

  data() {
    return {
      index: null,
      sliderImg: [],
      welcomeContent: {},
      releases: [],
      newses: [],
      projectSlider: [],
      projectText: {},
      significant: {},
      initiatives: [
        { image: initiativeImg },
        { image: initiativeImg },
        { image: initiativeImg },
        { image: initiativeImg },
        { image: initiativeImg },
        { image: initiativeImg },
      ],
      Gallarys: [],
      settings: {
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
    async getBannerSlider() {
      try {
        const response = await axios.get(`${baseUrl}/user/banner/slider`);
        this.sliderImg = response.data.data.images;
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },
    async getGallerySlider() {
      try {
        const response = await axios.get(`${baseUrl}/user/home/gallery/slider`);
        this.Gallarys = response.data.data;
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },

    async getProjectSlider() {
      try {
        const response = await axios.get(`${baseUrl}/user/concerns`);
        this.projectSlider = response.data.data;
        this.projectText = this.projectSlider[0];
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },

    async getReleases() {
      try {
        const response = await axios.get(`${baseUrl}/user/csr`);
        this.releases = response.data.data.slice(0,3);
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },

    async getNews() {
      try {
        const response = await axios.get(`${baseUrl}/user/news`);
        this.newses = response.data.data.slice(0,3);
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },

    async getWelcomeContent() {
      try {
        const response = await axios.get(`${baseUrl}/user/home/welcome`);
        this.welcomeContent = response.data.data;
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },

    async getSignificant() {
      try {
        const response = await axios.get(`${baseUrl}/user/home/project`);
        this.significant = response.data.data;
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },
  },
};
</script>

<style lang="scss">
.row {
  margin: 0px -15px;
}
.v-list-item,
p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000de;
  margin: 0 !important;
}
.homeSlider {
  padding-bottom: 50px;
  /* .v-window{
    min-height: 100%;
    .v-image{
      height:100%!important;
    }
  } */
  /* .v-image{
    height:100%;
    width:100%;
  } */
  img {
    height: 100%;
    width: 100%;
  }
}
.welcome-sec {
  padding-top: 0px;
  .wc-head {
    border-right: 3px solid #ed1c24;
    text-align: left;
  }
  h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #181818;
  }
  p {
    color: #000000de;
    padding-bottom: 10px;
  }
}
.significant {
  h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #181818;
  }
}
.project-card {
  margin-right: -40px;
  margin-top: 54px;
  z-index: 99;
  .v-card {
    padding: 10px 20px 20px 20px;
    text-align: center;
    border-radius: 20px !important;
    .v-card__title {
      justify-content: center;
    }
    .v-card__text {
      text-align: center;
      color: #000000de !important;
      font-size: 16px;
    }
    .v-btn {
      padding: 14px 54px;
      border-radius: 15px;
      height: 48px;
    }
  }
}
.project-carousel {
  .ourProjectSlider {
    .v-image {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(24, 24, 24, 0.4);
        z-index: 1;
      }
    }
    .v-responsive__content {
      z-index: 9;
    }
    p {
      color: #fff;
      padding-right: 40px;
      padding-top: 40px;
    }
  }
  .v-window {
    .v-window__next {
      background: transparent;
      button {
        background: rgba(255, 255, 255, 0.2);
      }
    }
    .v-window__prev {
      background: transparent;
      right: 0;
      text-align: right;
      top: 60%;
      button {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
.gallery {
  padding-top: 150px;
  // padding-bottom: 150px;
  h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #181818;
  }
  .v-icon {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #626262;
    color: #fff;
  }

  /* .slick-center.slick-current .v-responsive{
    height:30px !important;
  } */
}
/* .initiatives{
    padding:100px 140px;
    background:#FEF4F4;
  } */
.Latest-realeses {
  padding-top: 0px;
  h1 {
    font-family: "Poppins", sans-serif;
    color: #181818;
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
    padding-bottom: 30px;
  }
  .v-card__title {
    font-family: "Poppins", sans-serif !important;
    font-size: 18px;
    padding-bottom: 0px;
  }
  p,
  .v-card__text {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding-bottom: 20px;
  }
  .v-card {
    border-radius: 25px !important;
    position: relative;
    .v-btn::before {
      background: transparent !important;
    }
    .v-card__actions {
      position: absolute;
      bottom: 20px;
      left: 0;
    }
  }
}
@media only screen and (max-width: 1600px) {
  .project-card {
    margin-right: -40px;
    margin-top: 15px !important;
    z-index: 99;
    .v-card {
      padding: 10px 10px 20px 10px;
      text-align: center;
      border-radius: 20px !important;
      .v-card__title {
        justify-content: center;
      }
      .v-card__text {
        text-align: center;
      }
      .v-btn {
        padding: 14px 54px;
        border-radius: 15px;
        height: 48px;
      }
    }
  }
  .Latest-realeses {
    .v-card__title {
      font-size: 17px;
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .homeSlider {
    .v-window {
      height: 50vh !important;
      .v-image {
        height: 50vh !important;
      }
    }
  }
  .welcome-sec {
    padding-top: 0px;
    .wc-head {
      border-right: none;
      border-bottom: 2px solid #ed1c24;
      text-align: left;
    }
    h1 {
      font-size: 24px;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      word-spacing: 0.5px;
    }
  }
  .gallery {
    padding-top: 50px;
  }
  .Latest-realeses {
    padding-top: 50px;
  }
}
</style>
