<template>
  <div>
    <v-container fluid class="pb-0">
      <v-row>
        <v-toolbar flat :height="$vuetify.breakpoint.smAndDown ? '120px' : '100px'">
          <v-toolbar-title @click.stop="drawer = !drawer" style="cursor: pointer"
            ><v-app-bar-nav-icon></v-app-bar-nav-icon> Menu</v-toolbar-title
          >

          <v-spacer></v-spacer>
          <router-link to="/">
            <img v-if="logo.data.logo" :src="logo.data.logo" width="120" />
          </router-link>
          <v-spacer
            :style="$vuetify.breakpoint.smAndDown ? 'display:none' : ''"
          ></v-spacer>
          <v-toolbar-item
            class="mr-lg-5"
            :style="$vuetify.breakpoint.smAndDown ? 'display:none' : ''"
          >
            <v-btn flat to="/" plain>
              <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-menu open-on-hover offset-y transition="scale-transition" left bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn flat v-bind="attrs" v-on="on" plain to="/units-concerns">
                  Units & Concerns
                </v-btn>
              </template>

              <v-list v-for="(unit, unitIndex) in concern_units" :key="unitIndex">
                <v-list-item @click.stop="toggleSubmenu(unitIndex)">
                  <v-list-item-action>
                    <v-list-item-title>
                      <v-icon>
                        {{
                          activeMenuIndex === unitIndex
                            ? "mdi-chevron-up"
                            : "mdi-chevron-down"
                        }}
                      </v-icon>
                      {{ unit.name }}
                    </v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  class="pl-lg-15"
                  v-show="activeMenuIndex === unitIndex"
                  v-for="(concern, i) in unit.concerns"
                  :key="i"
                  router
                  :to="'/concern/' + concern.slug"
                >
                  <v-list-item-action>
                    <v-list-item-title>{{ concern.name }}</v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-list>
                <v-list-item
                  v-for="(linked_concern, linkedinIndex) in linked_concerns"
                  :key="linkedinIndex"
                  router
                  :to="'/concern/' + linked_concern.slug"
                  class="pl-8"
                >
                  <v-list-item-action>
                    <v-list-item-title>{{ linked_concern.name }}</v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn to="/csr" flat plain> CSR </v-btn>
            <v-btn to="/contact" flat plain> Contact </v-btn>
          </v-toolbar-item>
        </v-toolbar>
      </v-row>
    </v-container>

    <v-navigation-drawer
      class="side-drawer"
      v-model="drawer"
      fixed
      left
      temporary
      hide-overlay
      width="300px"
    >
      <v-list nav>
        <v-list-item class="logo">
          <v-list-item-title>
            <img v-if="logo.data.logo" :src="logo.data.logo" alt="logo" width="100px" />
          </v-list-item-title>
        </v-list-item>
        <v-list-item @mouseover="mouseOver1" :class="activeOver1 ? 'yes' : ''">
          <v-list-item-title>About Us</v-list-item-title>
        </v-list-item>
        <v-list-item @mouseover="mouseOver2" :class="activeOver2 ? 'yes' : ''">
          <v-list-item-title>Investor Info</v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          to="/units-concerns"
          @mouseover="mouseOver3"
          :class="activeOver3 ? 'yes' : ''"
        >
          <v-list-item-title> Units & Concerns</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/csr" @mouseover="mouseOver4">
          <v-list-item-title>CSR</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      class="drawer-side"
      v-model="drawer"
      width="500px"
      fixed
      left
      temporary
    >
      <v-list nav>
        <v-list-item class="close">
          <v-spacer></v-spacer>
          <v-list-item-icon @click.stop="drawer = !drawer">
            <v-icon color="#fff">mdi-close</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          v-show="activeOver1"
          v-for="(item, index) in about.data"
          :key="index"
          router
          :to="'/about/' + item.slug"
        >
          <v-list-item-action>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          v-show="activeOver1"
          v-for="(item, index) in teams.data"
          :key="index"
          router
          :to="'/team/' + item.slug"
        >
          <v-list-item-action>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          v-show="activeOver2"
          @mouseover="showSubmenu(index)"
          @mouseleave="hideSubmenu(index)"
          v-for="(item, index) in investorCategories.data"
          :key="index"
          router
          :class="['main-item']"
          :to="'/info/' + item.slug"
        >
          <v-list-item-action>
            <v-list-item-title>
              <v-list-item-title :class="[{ 'main-item-active': activeIndex === index }]">
                {{ item.name }}</v-list-item-title
              >

              <v-list-item
                v-show="activeIndex === index"
                v-for="(children, i) in item.children"
                :key="i"
                router
                :class="'sub-item'"
                :to="'/info/' + children.slug"
              >
                <v-list-item-action>
                  <v-list-item-title>{{ children.name }}</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-title>
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          v-show="activeOver3"
          @mouseover="showSubmenu(index)"
          @mouseleave="hideSubmenu(index)"
          v-for="(unit, index) in concern_units"
          :key="index"
          :class="['main-item']"
        >
          <v-list-item-action>
            <v-list-item-title>
              <v-list-item-title :class="[{ 'main-item-active': activeIndex === index }]">
                {{ unit.name }}
              </v-list-item-title>
              <v-list-item
                v-show="activeIndex === index"
                v-for="(concern, i) in unit.concerns"
                :key="i"
                :to="'/concern/' + concern.slug"
                router
                :class="'sub-item'"
              >
                <v-list-item-action>
                  <v-list-item-title>{{ concern.name }}</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-title>
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          v-show="activeOver3"
          @mouseover="showSubmenu(index)"
          @mouseleave="hideSubmenu(index)"
          v-for="(linked_concern, linkedinIndex) in linked_concerns"
          :key="linkedinIndex"
          router
          :to="'/concern/' + linked_concern.slug"
        >
          <v-list-item-action>
            <v-list-item-title>{{ linked_concern.name }}</v-list-item-title>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import axios from "axios";
import { baseUrl } from "@/config";
export default {
  data() {
    return {
      logo: null,
      concern_units: [],
      linked_concerns: [],
      activeMenuIndex: null,
      about: [],
      teams: [],
      activeIndex: null,
      investorCategories: [],
      drawer: null,
      activeOver1: false,
      activeOver2: false,
      activeOver3: false,
      activeOverM: false,
      activeOverM1: false,
      activeSubOver: false,
      activeSubOver1: false,
      aboutSubMenuOver: false,
      activeSubOverInvestor: false,
    };
  },
  methods: {
    mouseOver1: function () {
      this.activeOver1 = true;
      this.activeOver2 = false;
      this.activeOver3 = false;
      this.activeSubOver1 = false;
      this.activeSubOver = false;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOver2: function () {
      this.activeOver1 = false;
      this.activeOver2 = true;
      this.activeOver3 = false;
      this.activeSubOver1 = false;
      this.activeSubOver = false;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOver3: function () {
      this.activeOver1 = false;
      this.activeOver2 = false;
      this.activeOver3 = true;
      this.activeSubOver1 = false;
      this.activeSubOver = false;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOver4: function () {
      this.activeOver1 = false;
      this.activeOver2 = false;
      this.activeOver3 = false;
      this.activeSubOver1 = false;
      this.activeSubOver = false;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOverM: function () {
      this.activeOverM = true;
      this.activeSubOver1 = false;
      this.activeSubOver = false;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOverSide: function () {
      this.activeSubOver = true;
      this.activeSubOver1 = false;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOverSide1: function () {
      this.activeSubOver = false;
      this.activeSubOver1 = true;
      this.activeSubOverInvestor = false;
      this.aboutSubMenuOver = false;
    },
    mouseOverIpo: function () {
      this.activeSubOverInvestor = true;
    },
    outSide: function () {
      this.activeSubOverInvestor = false;
    },
    aboutSubMenuMouseOver(id) {
      if (id == 5) {
        this.aboutSubMenuOver = true;
      }
    },
    toggleSubmenu(index) {
      if (this.activeMenuIndex === index) {
        this.activeMenuIndex = null;
      } else {
        this.activeMenuIndex = index;
      }
    },

    showSubmenu(index) {
      this.activeIndex = index;
    },
    hideSubmenu(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      }
    },

    async getLogo() {
      try {
        const response = await axios.get(`${baseUrl}/user/logo`);
        this.logo = response.data;
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },

    async getConcernUnit() {
      try {
        const response = await axios.get(`${baseUrl}/user/concern/unit`);
        this.concern_units = response.data.data.concern_units;
        this.linked_concerns = response.data.data.linked_concerns;
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },

    async getInvestorCategories() {
      try {
        const response = await axios.get(`${baseUrl}/user/investor/categories`);
        this.investorCategories = response.data;
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },

    async getAbouts() {
      try {
        const response = await axios.get(`${baseUrl}/user/about`);
        this.about = response.data;
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },

    async getTeams() {
      try {
        const response = await axios.get(`${baseUrl}/user/team`);
        this.teams = response.data;
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },
  },
  mounted() {
    this.getLogo();
    this.getConcernUnit();
    this.getInvestorCategories();
    this.getAbouts();
    this.getTeams();
  },
};
</script>
<style lang="scss">
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 5px !important;
}
/* .v-overlay--active{
.v-overlay__scrim{
  opacity: 0.95 !important;
}} */
/* .v-menu__content{
  left: 1380px !important;
} */
.side-drawer.v-navigation-drawer {
  z-index: 99999;
  background-color: rgb(24, 24, 24, 0.6) !important;
  box-shadow: none !important;
  .yes {
    background-color: rgb(237, 28, 36);
  }
  .v-list-item {
    cursor: pointer;
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
  .v-list-item.logo {
    background-color: transparent !important;
  }
  .v-list-item:hover {
    background-color: rgb(237, 28, 36);
    color: #fff !important;
  }
  .v-list-item--active {
    color: #fff !important;
    background-color: rgb(237, 28, 36);
  }
}
.drawer-side.v-navigation-drawer {
  left: 298px !important;
  top: 0;

  background-color: rgb(24, 24, 24, 0.6) !important;
  box-shadow: none !important;
  z-index: 99999;
  .v-list-item {
    cursor: pointer;
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #fff;
  }
  .close {
    background-color: transparent !important;
    cursor: pointer;
    padding-bottom: 25px;
  }
  // .v-list-item:hover {
  //   background-color: rgb(237, 28, 36);
  //   text-align: center;
  // }
  // .v-list-item--active {
  //   color: #fff !important;
  //   background-color: rgb(237, 28, 36);
  // }
}
@media only screen and (min-width: 768px) {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 4px 0px !important;
  }
  .side-drawer.v-navigation-drawer {
    width: 250px !important;
    .v-list-item {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: left !important;
    }
  }
  .drawer-side.v-navigation-drawer {
    width: 450px !important;
    left: 248px !important;
    top: 0;
    .v-list-item {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: left !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 4px 0px !important;
  }
  .side-drawer.v-navigation-drawer {
    width: 180px !important;
    .v-list-item {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      text-align: left !important;
    }
  }
  .drawer-side.v-navigation-drawer {
    width: 250px !important;
    left: 178px !important;
    top: 0;
    .v-list-item {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      text-align: left !important;
    }
  }
}
@media only screen and (max-width: 390px) {
  .side-drawer.v-navigation-drawer {
    width: 180px !important;
    .v-list-item {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      text-align: left !important;
    }
  }
  .drawer-side.v-navigation-drawer {
    width: 200px !important;
    left: 178px !important;
    top: 0;
    .v-list-item {
      font-weight: 600;
      font-size: 10px;
      line-height: 24px;
      text-align: left !important;
    }
  }
}
@media only screen and (max-width: 320px) {
  .side-drawer.v-navigation-drawer {
    width: 150px !important;
    box-sizing: border-box !important;
    .v-list-item {
      box-sizing: border-box !important;
      font-weight: 400;
      font-size: 10px;
      line-height: 24px;
      text-align: left !important;
    }
  }
  .drawer-side.v-navigation-drawer {
    width: 170px !important;
    left: 45% !important;
    top: 0;
    display: block !important;
    box-sizing: border-box !important;
    .v-list-item {
      box-sizing: border-box !important;
      font-weight: 400;
      font-size: 10px;
      line-height: 24px;
      text-align: left !important;
    }
  }
}

.main-item:hover {
  background-color: transaparent;
}

.main-item-active {
  background-color: transaparent;
}

.sub-item {
  padding-left: 20px !important;
  transition: background-color 0.3s ease;
  margin-top: 10px !important;
}

.sub-item:hover {
  background-color: rgb(237, 28, 36);
}
</style>
