import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../views/HomeView.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
  },
  {
    path: "/info/:slug",
    name: "Investor Info",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InvestorInfoView.vue"),
  },
  
  {
    path: "/about/:slug",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/OurMissionView.vue"),
  },

  {
    path: "/units-concerns",
    name: "units concerns",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UnitsConcernsView.vue"),
  },
  {
    path: "/team/:slug",
    name: "Team",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BoardDirectorsView.vue"),
  },

  {
    path: "/csr",
    name: "CSR",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CsrView.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewsView.vue"),
  },
  {
    path: "/concern/:slug",
    name: "concern single",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      Parent: "units-concerns",
    },
  },
  {
    path: "/csr/:slug",
    name: "csr single",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/projectDetail.vue"),
    props: {
      Parent: "csr",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
