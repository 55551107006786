<template>
  <v-footer>
    <v-container>
      <v-row>
        <v-col md="3" class="text-left" cols="12">
          <div class="footer-text">
            <h1>{{welcomeContent.site_name}}</h1>
            <div v-for="(o, i) in office" :key="i">
              <v-icon>mdi-map-marker-outline</v-icon>
              <span>{{ o.content.address }}</span> <br />
              <span> {{ o.content.numbers }}</span>
            </div>
          </div>
        </v-col>
        <v-col md="3" lg="3" cols="12" offset-lg="1" class="footer-midle">
          <v-list>
            <v-list-item class="white--text"><strong>Visit</strong></v-list-item>
            <v-list-item
              class="white--text"
              style="cursor: pointer"
              router
              :to="item.route"
              v-for="(item, i) in items"
              :key="i"
              >{{ item.name }}</v-list-item
            >
          </v-list>
        </v-col>
        <v-col lg="5" md="4" cols="12" class="footer-right text-left">
          <h3 class="text-left mb-3">Stay connected</h3>
          <v-form @submit.prevent="formSubmit()" ref="form" v-model="valid">
            <v-item-group class="d-flex">
              <v-text-field
                label="Enter your email"
                placeholder="Enter your email"
                solo
                v-model="email"
                :rules="emailRules"
                height="50px"
                required
              ></v-text-field>
              <v-btn
                color="#ED1C24"
                height="50px"
                style="margin-left: -5px"
                type="submit"
              >
                Subscribe
              </v-btn>
            </v-item-group>
          </v-form>
          <div v-if="socials.length > 0">
            <a v-for="(icon, i) in socials" :key="i" :href="icon.link"
              ><v-icon
                class="white rounded-circle py-1 px-1 ml-2"
                size="30"
                :color="
                  icon.name === 'facebook'
                    ? '#4267B2'
                    : icon.name === 'twitter'
                    ? '#1DA1F2'
                    : '#FF0000'
                "
                >mdi-{{ icon.name }}</v-icon
              ></a
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div style="background: rgba(255, 255, 255, 0.27); width: 100%; margin-top: 20px">
      <v-container>
        <v-row>
          <v-col cols="12" class="d-lg-flex py-2">
            <p>&copy; {{new Date().getFullYear()}} {{welcomeContent.site_name}}. All rights reserved</p>
            <v-spacer></v-spacer>
            <p>
              Design and Developed by
              <a href="https://dmarkcy.com/" class="white--text">dMarkcy</a>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-footer>
</template>
<script>
import axios from "axios";
import { baseUrl } from "@/config";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      valid: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      items: [
        { name: "Home", route: "/" },
        { name: "Units & Concerns", route: "/units-concerns" },
        { name: "Investor Information", route: "/investor-information" },
        { name: "CSR", route: "/csr" },
      ],
      socials: [],
      office: [],
      welcomeContent: {}
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    async getSocials() {
      try {
        const response = await axios.get(`${baseUrl}/user/social`);
        this.socials = response.data.data;
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },
    async getOffice() {
      try {
        const response = await axios.get(`${baseUrl}/user/locations`);
        this.office = response.data.data.slice(0, 1);
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },

    async getWelcomeContent() {
      try {
        const response = await axios.get(`${baseUrl}/user/home/welcome`);
        this.welcomeContent = response.data.data;
        
      } catch (error) {
        console.error("Error Fetching data.", error);
      }
    },
    async formSubmit() {
      if (this.validate()) {
        const formData = {
          email: this.email,
        };
        try {
          const response = await axios.post(`${baseUrl}/user/newsletter`, formData, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.status) {
            Swal.fire({
              icon: "success",
              title: "Mail Submitted!",
              text: "Thanks for submitting your mail.",
            });
            this.email = "";
            this.valid = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed to submit!",
              text: "Failed to submit the form.",
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Failed to submit!",
            text: "An error occurred while submitting the form.",
          });
        }
      }
    },
  },
  mounted() {
    this.getSocials();
    this.getOffice();
    this.getWelcomeContent();
  },
};
</script>

<style lang="scss">
.v-footer {
  margin-top: 150px;
  padding: 60px 0px 0px 0px !important;
  background-color: #181818 !important;
  .v-list-item--active {
    background: transparent !important;
  }
  h1,
  p,
  .v-icon,
  span {
    color: #fff;
  }
  .v-icon {
    font-size: 20px;
  }
  span {
    margin-left: 10px;
  }
  .theme--light.v-sheet {
    background-color: transparent;
  }
  .v-input {
    background: transparent;
  }
  .footer-midle {
    strong {
      border-left: 2px solid #ed1c24;
      padding-left: 5px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .footer-right {
    h3 {
      font-size: 25px;
      line-height: 37px;
      border-left: 2px solid #ed1c24;
      padding-left: 5px;
      color: #fff;
      font-weight: 600;
    }
    p {
      font-size: 16px;
      line-height: 27px;
      padding-top: 24px;
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .v-footer {
    margin-top: 50px;
    padding: 20px 0px !important;
    .footer-midle {
      strong {
        border-left: none;
        padding-left: 0px;
      }
      .v-list-item {
        padding: 0px;
        padding-left: 10px;
      }
    }
    .footer-right {
      h3 {
        font-size: 25px;
        line-height: 37px;
        border-left: none;
        padding-left: 0px;
      }
    }
  }
}
</style>
